body {
  height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bg-image {
  background-image: url('./assets/track-bg1.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  height: 100vh;
  width: 100vw;
  z-index: -1;
  overflow-y: auto;
}

.dropcap.dropcap-bg {
  background: none repeat scroll 0 0 #f69087;
  color: #fff;
  padding: 4px 8px;
  font-size: 45px;
}

.dropcap {
  float: left;
  font-size: 50px;
  font-weight: 700;
  line-height: 35px;
  margin-right: 7px;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  top: 1px;
}